.tour-planner-page {
  $bar-bg: #f8f9fa;

  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;

  xyz-web-header {
    min-height: 42px;

    label {
      font-size: 14px;
      font-weight: 500;
      color: var(--text-color);
      font-weight: normal;
      cursor: pointer;
      user-select: none;
    }
  }

  &.tours-planning {
    .content-wrapper {
      grid-template-columns: 450px auto !important;
    }
  }

  &.customize-header {
    xyz-web-header {
      .title-wrapper {
        width: 100%;
      }
    }
  }

  .content-wrapper {
    flex: 1 1 1px;
    min-height: 0;
    display: grid;
    grid-template-columns: 330px auto;
    grid-template-rows: auto 300px;
    grid-template-areas:
      'sidebar tours'
      'sidebar map';

    xyz-web-cases-list {
      grid-area: sidebar;
      overflow: hidden;
      max-height: 100%;
    }

    xyz-web-tours-list, xyz-web-tours-list-v2 {
      grid-area: tours;
      overflow: hidden;
      max-height: 100%;
    }

    xyz-web-tours-route-map {
      grid-area: map;
    }
  }

  &.overlay-content {
    .content-wrapper {
      grid-template-columns: 330px auto 500px;
      grid-template-areas:
        'sidebar tours map'
        'sidebar tours map';
    }
  }

  .bar {
    background-color: $bar-bg;
    border-bottom: 1px solid var(--border-color);
    min-height: 40px;
    padding: 0 1rem;
    font-size: 14px;
  }

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: var(--text-color);
    font-weight: 500;
    position: relative;
  }
}

.cdk-drag-placeholder {
  opacity: 0;
}
