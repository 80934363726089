.text-title {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 17px;
  color: var(--gray-800);
}

.text-description {
  font-size: 14px;
  line-height: 14px;
  color: var(--gray-700);
}
.text-bold {
  font-weight: 700;
}

.text-label {
  color: #9E9E9E
}

.text-headerbar-title {
  font-style: normal;
  font-weight: 600;
  font-size: 1.125rem;
  line-height: 20px;
  /* identical to box height */

  display: flex;
  align-items: center;

  /* Text color default */

  color: var(--xyz-text-color);
}

.text-section-title {
  color: var(--xyz-text-color);
  font-style: normal;
  font-weight: 700;
  font-size: 1rem;
  line-height: 20px;
}
