  @import './assets/scss/index.scss';
  @import './assets/scss/inputs.scss';

/* You can add global styles to this file, and also import other style files */
html,
body {
  margin: 0;
  padding: 0;
  font-size: 16px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif,
    'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  color: var(--text-color);
}

.sub-bar {
  background: #ffffff;
  height: 3.125rem;
  padding: 0 1rem;
  border-bottom: 2px solid transparent;
  box-sizing: border-box;
  display: flex;
  width: 100%;
  position: relative;
  z-index: 3;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0px 2px 8px rgb(0 0 0 / 16%);

  &.no-shadow {
    box-shadow: none;
    border-bottom: 1px solid var(--border-color);
  }

  &.no-border {
    border: none;
  }
}

.inner-scroll {
  overflow: auto;
  max-height: calc(100vh - 64px);

  &.double-header {
    max-height: calc(100vh - 114px);
  }
}

.link {
  cursor: pointer;
  color: var(--xyz-button-blue);
}

.table-link {
  font-weight: bold;
  font-size: 0.875rem;
  @extend .link;
}

.pointer {
  cursor: pointer;
}

[hidden] {
  display: none !important;
}

:root {
  --border-color: #dee2e6;
  --red: #d32f2f;
  --red-shadow: #d68080;
  --orange: #ff8a00;
  --green: #8cc736;
  --xyz-success-color: #337c20;
  --xyz-warning-color: #ff8a00;
  --xyz-danger-color: #d32f2f;
  --xyz-text-color: #495057;
  --dark-green: #28a745;
  --status-gray: #79838d;
  --text-black: #1a242d;
  --sidebar-bg: #495057;
  --color-primary-50: #e4f2fe;
  --color-primary-100: #bce0fb;
  --color-primary-200: #90cbf9;
  --color-primary-300: #64b6f7;
  --color-primary-400: #42a6f5;
  --color-primary-500: #2196f3;
  --color-primary-600: #1d8ef1;
  --color-primary-700: #1883ef;
  --color-primary-800: #1479ed;
  --color-primary-900: #0b68ea;
  --color-primary-A100: #ffffff;
  --color-primary-A200: #e1ecff;
  --color-primary-A400: #aeccff;
  --color-primary-A700: #95bcff;
  --form-elements-gap: 20px;

  // Default styles
  --border-color: #dee2e6;
  --red: #d32f2f;
  --red-shadow: #d68080;
  --green: #8cc736;
  --green-rgb: 69, 166, 23;
  --dark-green: #28a745;
  --status-gray: #79838d;
  --text-black: #1a242d;
  --sidebar-bg: #495057;
  --sidebar-active-bg: rgb(109, 115, 122);
  --sidebar-text: #ffffff;
  --sidebar-top-height: 64px;
}

.dispo-input-form-group {
  margin: 20px;
  margin-top: 25px;
  margin-bottom: 25px;
}

.cdk-overlay-container {
  z-index: 1200;
}